<template>
    <div>
        <footer>
            <div class="footer">
                <p>© 2023 Zyncas. All rights reserved.</p>
                <ul>
                    <li><a href="#" target="_blank"><img src="../assets/twitter.png" alt=""></a></li>
                    <li><a href="#" target="_blank"><img src="../assets/tele.png" alt=""></a></li>
                    <li><a href="#" target="_blank"><img src="../assets/discord.png" alt=""></a></li>
                </ul>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    footer{
        padding-bottom: 15px;
        margin-top: 50px;
        .footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 60px;
            border-top: 1px solid rgba(0, 0, 0, 0.5);
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
            p{
                font-size: 14px;
                font-weight: 500;
                color: #000;
                text-transform: uppercase;
                margin: 0;
            }
            ul{
                display: flex;
                gap: 15px;
            }
        }
        @media (max-width: 992px){
            display: none;
        }
    }
</style>