<template>
    <div class="fullPage">
        <div class="banner">
            <img class="img_token_1" src="../assets/token_1.png" alt="">
            <img class="img_token_2" src="../assets/token_2.png" alt="">
            <img class="img_token_3" src="../assets/token_2.png" alt="">
            <img class="img_token_4" src="../assets/token_1.png" alt="">
            <h2>Zyncas Token</h2>
            <p>ZCS is a Utility token that using with Zyncas dApp and Ecosystem</p>
            <ul>
                <li><a href="https://app.signals.zyncas.com/zodiac-arena" target="_blank">Buy NFT</a></li>
                <li><a href="https://nft.zyncas.com" target="_blank">NFT Page</a></li>
            </ul>
        </div>
        <div class="unility">
            <div class="title" id="utilities">
                <h2>Token Utilities</h2>
            </div>
            <div class="listBox">
                <div class="item">
                    <div class="imgContent">
                        <img src="../assets/icon_1.png" alt="">
                    </div>
                    <div class="content">
                        <h2>Governance Voting</h2>
                        <p>Shape the platform's future with your vote,<br> influencing key decisions and policies.</p>
                    </div>
                </div>
                <div class="item">
                    <div class="imgContent">
                        <img src="../assets/icon_2.png" alt="">
                    </div>
                    <div class="content">
                        <h2>Staking Rewards</h2>
                        <p>Earn rewards by staking your assets,<br> enhancing network security and operations.</p>
                    </div>
                </div>
                <div class="item">
                    <div class="imgContent">
                        <img src="../assets/icon_3.png" alt="">
                    </div>
                    <div class="content">
                        <h2>Liquidity Pooling</h2>
                        <p>Contribute to liquidity pools to earn trade fees,<br> improving market stability.</p>
                    </div>
                </div>
                <div class="item">
                    <div class="imgContent">
                        <img src="../assets/icon_4.png" alt="">
                    </div>
                    <div class="content">
                        <h2>Liquidity Mining</h2>
                        <p>Boost your earnings by providing liquidity,<br> gaining extra rewards for your support.</p>
                    </div>
                </div>
                <div class="item">
                    <div class="imgContent">
                        <img src="../assets/icon_5.png" alt="">
                    </div>
                    <div class="content">
                        <h2>Trading on <a target="_blank" href="http://zperp.io/">zperp.io</a></h2>
                        <p>Trade securely and efficiently on <a target="_blank" href="http://zperp.io/">zperp.io</a>,<br> a user-centric decentralized exchange.</p>
                    </div>
                </div>
                <!-- <div class="item">
                    <div class="imgContent">
                        <img src="../assets/icon_6.png" alt="">
                    </div>
                    <div class="content">
                        <h2>Delegation NFT Gorvernance Pool</h2>
                        <p>Users who lock their <strong>ZCS</strong> tokens Delegation Pool to vote on governance proposals</p>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="tokenomic">
            <div class="title" id="tokenomic">
                <h2>Tokenomics</h2>
            </div>
            <div class="chartDonut">
                <div class="addChart">
                    <apexchart  type="donut" :options="options" :series="series"></apexchart>
                    <!-- <span class="totalSupply">200M</span> -->
                </div>
                <div class="legendChart">
                    <ul>
                        <li>Comunity dao <span>50%</span></li>
                        <li>Seed Sale <span>5%</span></li>
                        <li>Private Sale <span>10%</span></li>
                        <li>Public Sale <span>5%</span></li>
                        <li>Contributor <span>20%</span></li>
                        <li>Marketing <span>5%</span></li>
                        <li>Airdrop <span>5%</span></li>
                    </ul>
                </div>
            </div>
            <div class="lineChart">
                <div class="showChart">
                    <apexchart type="area" height="550" :options="chartOptions" :series="seriesLine"></apexchart>
                </div>
            </div>
            <div class="box">
                <div class="listBox">
                    <div class="item">
                        <div class="top">
                            <h2 >Seed sale <br>
                                <!-- <span>06/2023</span> -->
                            </h2>
                        </div>
                        <div class="bot">
                            <h4>Amount Raise</h4>
                            <p>$500.000</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <h2>Private Sale <br>
                                <!-- <span>07/2023</span> -->
                            </h2>
                        </div>
                        <div class="bot">
                            <h4>Amount Raise</h4>
                            <p>$2.000.000</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="top">
                            <h2>Public Sale <br>
                                <!-- <span>08/2023</span> -->
                            </h2>
                        </div>
                        <div class="bot">
                            <h4>Amount Raise</h4>
                            <p>$2.500.000</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="roadmap">
            <div class="title" id="roadmap">
                <h2>Roadmap</h2>
            </div>
            <div class="content">
                <img class="line_rn_vert" src="../assets/line_rn_vert.png" alt="">
                <div class="top">
                    <div class="item ">
                        <h2>10/2024</h2>
                        <div class="time">
                            <p><a target="_blank" href="http://zperp.io/">zperp.io</a> DEX Testnet</p>
                            <!-- <h5>07/2023</h5> -->
                        </div>
                        <img src="../assets/lineItem.png" alt="">
                    </div>
                    <div class="item">
                        <h2>12/2024</h2>
                        <div class="time">
                            <p>ZCS Token release</p>
                            <!-- <h5>10/2023</h5> -->
                        </div>
                        <img src="../assets/lineItem.png" alt="">
                    </div>
                    <!-- <div class="item">
                        <h2>Phase 6</h2>
                        <div class="time">
                            <p>Copy Trade</p>
                            <h5>Q2 - 2023</h5>
                        </div>
                        <img src="../assets/lineItem.png" alt="">
                    </div>
                    <div class="item">
                        <h2>Phase 8</h2>
                        <div class="time">
                            <p>Visualize Whale <br>Tracking Dashboard</p>
                            <h5>Q4 - 2024</h5>
                        </div>
                        <img src="../assets/lineItem.png" alt="">
                    </div> -->
                </div>
                <div class="line">
                    <img src="../assets/lineRoadmap.png" alt="">
                </div>
                <div class="bot">
                    <div class="item active">
                        <img src="../assets/lineItem.png" alt="">
                        <h2>9/2024</h2>
                        <div class="time">
                            <p>NFT Public sales</p>
                            <!-- <h5>06/2023</h5> -->
                        </div>
                    </div>
                    <div class="item">
                        <img src="../assets/lineItem.png" alt="">
                        <h2>11/2024</h2>
                        <div class="time">
                            <p><a target="_blank" href="http://zperp.io/">zperp.io</a> DEX Mainnet</p>
                            <!-- <h5>08/2023</h5> -->
                        </div>
                    </div>
                    <div class="item">
                        <img src="../assets/lineItem.png" alt="">
                        <h2>1/2025</h2>
                        <div class="time">
                            <p>DAO/ Staking / Farming</p>
                            <!-- <h5>Q1 - 2024</h5> -->
                        </div>
                    </div>
                    <!-- <div class="item">
                        <img src="../assets/lineItem.png" alt="">
                        <h2>Phase 7</h2>
                        <div class="time">
                            <p>Money Flow Dashboard</p>
                            <h5>Q3 - 2024</h5>
                        </div>
                    </div>
                    <div class="item">
                        <img src="../assets/lineItem.png" alt="">
                        <h2>Phase 9</h2>
                        <h5>02/2024</h5>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {  },

        data() {
            return {
                options: {
                    labels: ['Comunity dao', 'Seed sales', 'Private Sales', 'Public Sales', 'Contributor', 'Marketing', 'Airdrop'],
                    cssClass: 'custom-1',
                    responsive: [{
                        breakpoint: 992,
                        options: {
                            chart: {
                                width: 400
                            },
                        }
                    }],
                    legend: {
                        show: false,
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    total: {
                                        show: true,
                                        label: 'Total Supply',
                                        showAlways: true,
                                        fontSize: '24px',
                                        fontWeight: 700,
                                        fontFamily: 'Montserrat',
                                        color: '#000',

                                    },
                                    name: {
                                        fontSize: '22px',
                                        fontWeight: 700,
                                        fontFamily: 'Montserrat',
                                        offsetY: 10,
                                        color: '#000',
                                        show: true,
                                    },
                                    value: {
                                        fontSize: '48px',
                                        fontWeight: 700,
                                        fontFamily: 'Neboma Sporte',
                                        offsetY: 40,
                                        color: '#000',
                                        show: false,
                                        
                                    }
                                }
                            }
                        }
                    }
                },
                series: [50, 5, 10, 5, 20, 5, 5],

                seriesLine: [
                    {
                        name: "Airdrop",
                        data: [5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, ]
                    },
                    {
                        name: "Contributor",
                        data: [4.00,	4.00,	4.00,	4.00,	4.00,	4.00,	4.00,	4.00,	4.00,	4.00,	4.00,	4.00,	4.00,	4.44,	4.89,	5.33,	5.78,	6.22,	6.67,	7.11,	7.56,	8.00,	8.44,	8.89,	9.33,	9.78,	10.22,	10.67,	11.11,	11.56,	12.00,	12.44,	12.89,	13.33,	13.78,	14.22,	14.67,	15.11,	15.56,	16.00,	16.44,	16.89,	17.33,	17.78,	18.22,	18.67,	19.11,	19.56,	20.00,]
                    },
                    
                    {
                        name: "Private Sales",
                        data: [1.00,	1.00,	1.00,	1.00,	1.00,	1.00,	1.00,	1.00,	1.00,	1.00,	1.00,	1.00,	1.00,	1.38,	1.75,	2.13,	2.50,	2.88,	3.25,	3.63,	4.00,	4.38,	4.75,	5.13,	5.50,	5.88,	6.25,	6.63,	7.00,	7.38,	7.75,	8.13,	8.50,	8.88,	9.25,	9.63,	10.00,	10.00,	10.00,	10.00,	10.00,	10.00,	10.00,	10.00,	10.00,	10.00,	10.00,	10.00,	10.00,]
                    },
                    {
                        name: "Seed Sale",
                        data: [0.50,	0.50,	0.50,	0.50,	0.50,	0.50,	0.50,	0.50,	0.50,	0.50,	0.50,	0.50,	0.50,	0.69,	0.88,	1.06,	1.25,	1.44,	1.63,	1.81,	2.00,	2.19,	2.38,	2.56,	2.75,	2.94,	3.13,	3.31,	3.50,	3.69,	3.88,	4.06,	4.25,	4.44,	4.63,	4.81,	5.00,	5.00,	5.00,	5.00,	5.00,	5.00,	5.00,	5.00,	5.00,	5.00,	5.00,	5.00,	5.00, ]
                    },
                    {
                        name: "Comunity dao",
                        data: [5.00, 5.94, 6.88, 7.81, 8.75, 9.69, 10.63, 11.56, 12.50, 13.44, 14.38, 15.31, 16.25, 17.19, 18.13, 19.06, 20.00, 20.94, 21.88, 22.81, 23.75, 24.69, 25.63, 26.56, 27.50, 28.44, 29.38, 30.31, 31.25, 32.19, 33.13, 34.06, 35.00, 35.94, 36.88, 37.81, 38.75, 39.69, 40.63, 41.56, 42.50, 43.44, 44.38, 45.31, 46.25, 47.19, 48.13, 49.06, 50.00, ]
                    },
                    {
                        name: "Marketing",
                        data: [0.50, 0.88, 1.25, 1.63, 2.00, 2.38, 2.75, 3.13, 3.50, 3.88, 4.25, 4.63, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, ]
                    },
                    {
                        name: "Public Sales",
                        data: [5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, 5.00, ]
                    },
                    
                ],
                chartOptions: {
                    chart: {
                        height: 400,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    responsive: [{
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 400,
                                zoom: {
                                    enabled: true
                                }
                            },
                        }
                    }],
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        text: '$ZCS Emissions',
                        align: 'center',
                    },
                    // yaxis: {
                    //     min: 0,
                    //     max: 100
                    // },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: [
                            'Jul-24','Aug-24', 'Sep-24', 'Oct-24', 'Nov-24', 'Dec-24', 'Jan-25', 'Feb-25', 'Mar-25', 'Apr-25', 'May-25', 'Jun-25', 'Jul-25', 'Aug-25', 'Sep-25', 'Oct-25', 'Nov-25', 'Dec-25', 'Jan-26', 'Feb-26', 'Mar-26', 'Apr-26', 'May-26', 'Jun-26', 'Jul-26', 'Aug-26', 'Sep-26', 'Oct-26', 'Nov-26', 'Dec-26', 'Jan-27', 'Feb-27', 'Mar-27', 'Apr-27', 'May-27', 'Jun-27', 'Jul-27','Aug-27','Sep-27','Oct-27','Nov-27','Dec-27','Jan-28','Feb-28','Mar-28','Apr-28','May-28','Jun-28','Jul-28'
                        ],
                    }
                },
                
            };
        },
        methods: {
            
        },
    }
</script>

<style lang="scss" scoped>
    @keyframes animated_1 {
        0%{
            transform: translate(0px, 0px);
        }
        50%{
            transform: translate(30px, 30px);
        }
        100%{
            transform: translate(0px, 0px);
        }
    }
    @keyframes animated_2 {
        0%{
            transform: translate(0px, 0px);
        }
        50%{
            transform: translate(-30px, -30px);
        }
        100%{
            transform: translate(0px, 0px);
        }
    }
    .fullPage{
        padding-top: 93px;
        .title{
            padding: 120px 0 70px;
            h2{
                font-size: 40px;
                font-weight: bold;
                margin: 0;
                font-family: 'Neboma Sporte';
                color: #000;
                text-align: center;
            }       
        }
        .banner{
            height: calc(100vh - 93px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            border-bottom: 1px solid #000;
            >img{
                position: absolute;
                &.img_token_1{
                    top: 32%;
                    left: 15%;
                    animation: animated_1 3s linear infinite;
                }
                &.img_token_2{
                    top: 30%;
                    right: 10%;
                    width: 50px;
                    aspect-ratio: 1;
                    animation: animated_2 3s linear infinite;

                }
                &.img_token_3{
                    left: 16%;
                    bottom: 27%;
                    animation: animated_2 3s linear infinite;

                }
                &.img_token_4{
                    right: 13%;
                    bottom: 23%;
                    animation: animated_1 3s linear infinite;

                }
            }
            h2{
                font-family: 'Neboma Sporte';
                font-weight: 400;
                font-size: 80px;
                line-height: 80px;
                color: #000;
                margin-bottom: 30px;
            }
            p{
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                color: #000000;
                margin-bottom: 40px;
            }
            ul{
                display: flex;
                gap: 25px;
                li{
                    a{
                        height: 44px;
                        width: 140px;
                        font-size: 16px;
                        font-weight: 600;
                        text-transform: uppercase;
                        display: inline-block;
                        text-align: center;
                        line-height: 44px;
                        text-decoration: none;
                    }
                    &:first-child{
                        a{
                            border: 1px solid #000;
                            background: #000;
                            color: #fff;
                            &:hover{
                                color: #000;
                                background: #fff;
                            }
                        }
                    }
                    &:last-child{
                        a{
                            color: #000;
                            border-top: 1px solid rgba(0, 0, 0, 0.5);
                            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                            &:hover{
                                background: #000;
                                border: 1px solid #000;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        .unility{
            border-bottom: 1px solid #000;
            padding: 60px 0 100px;
            .listBox{
                display: flex;
                flex-direction: column;
                width: 60%;
                margin: auto;
                gap: 100px;
                .item{
                    display: flex;
                    align-items: center;
                    gap: 70px;
                    width: 70%;
                    .imgContent{
                        padding: 30px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.06);
                        transition: all .5s ease;
                    }
                    .content{
                        h2{
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 32px;
                            color: rgba(0, 0, 0, 0.7);
                            margin-bottom: 15px;
                        }
                        p{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            margin: 0;
                            color: rgba(0, 0, 0, 0.7);
                        }
                    }
                    &:nth-child(even){
                        // justify-content: flex-end;
                        margin-left: auto;
                    }
                    &:hover{
                        .imgContent{
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
        .tokenomic{
            border-bottom: 1px solid #000;
            padding: 60px 0 100px;
            .chartDonut{
                display: flex;
                padding: 0 60px;
                .addChart{
                    width: 40%;
                    position: relative;
                    span.totalSupply{
                        position: absolute;
                        font-size: 56px;
                        font-weight: 700;
                        color: #000;
                        top: 58%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-family: 'Neboma Sporte';

                    }
                }
                .legendChart{
                    width: 60%;
                    ul{
                        width: 60%;
                        margin: auto;
                        li{
                            display: flex;
                            justify-content: space-between;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 24px;
                            text-transform: uppercase;
                            padding: 15px 0;
                            &:not(:last-child){
                                border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                            }
                            &:nth-child(1){
                                color: rgb(0, 143, 251);
                            }
                            &:nth-child(2){
                                color: rgb(0, 227, 150);
                            }
                            &:nth-child(3){
                                color: rgb(254, 176, 25);
                            }
                            &:nth-child(4){
                                color: rgb(255, 69, 96);
                            }
                            &:nth-child(5){
                                color: rgb(119, 93, 208);
                            }
                            &:nth-child(6){
                                color: rgb(0, 143, 251);
                            }
                            &:nth-child(7){
                                color: rgb(0, 227, 150);
                            }
                            &:nth-child(8){
                                color: rgb(254, 176, 25);
                            }
                        }
                    }
                }
            }
            .lineChart{
                margin-top: 70px;
                padding: 20px 60px;
                margin-bottom: 50px;
                .showChart{
                    padding: 20px 0px;
                    border: 1px solid #000;
                }
            }
            .box{
                padding: 0 60px;
                .listBox{
                    display: flex;
                    gap: 25px;
                    .item{
                        border: 1px solid rgba(0, 0, 0, 0.3);
                        text-align: center;
                        width: 100%;
                        transition: all .5s ease;
                        .top{
                            background: #000;
                            padding: 15px 0;
                            h2{
                                font-weight: 800;
                                font-size: 18px;
                                line-height: 24px;
                                text-transform: uppercase;
                                color: rgba(255, 255, 255, 0.9);
                                width: fit-content;
                                margin: auto;
                                span{
                                    color: rgba(255, 255, 255, 0.6);
                                    margin-top: 8px;
                                    display: inline-block;
                                }
                                &.stl{
                                    text-decoration: line-through;
                                }
                            }
                        }
                        .bot{
                            padding: 25px 0;
                            h4{
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 24px;
                                color: #000000;
                                margin-bottom: 12px;
                            }
                            p{
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 24px;
                                margin-bottom: 0;
                                color: #000000;

                            }
                        }
                        @media (min-width: 993px){
                            &:hover{
                                margin-top: -20px;
                            }
                        }
                    }
                }
            }
        }
        .roadmap{
            padding: 60px 0 50px;
            overflow: hidden;
            .content{
                padding: 100px 0;
                img.line_rn_vert{
                    display: none;
                }
                .top, .bot{
                    display: flex;
                    padding: 0 40px;
                    .item{
                        text-align: center;
                        position: relative;
                        width: 200px;
                        .time{
                            height: 62px;
                        }
                        &:after{
                            content: '';
                            width: 24px;
                            aspect-ratio: 1;
                            border: 4px solid #000;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%) rotate(45deg);
                            background: #fff;
                            transition: all .5s ease;
                        }
                        h2{
                            font-family: 'Neboma Sporte';
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 30px;
                            color: #000000;
                            margin-bottom: 10px;
                        }
                        p{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            color: #000000;
                            white-space: nowrap;
                            margin-bottom: 5px;
                        }
                        h5{
                            font-weight: normal;
                            font-size: 14px;
                            margin: 0;
                        }
                        &.active, &:hover{
                            &:after{
                                border: 13px solid #000;
                            }
                        }
                    }
                }
                .top{
                    justify-content: space-evenly;
                    .item{
                        
                        img{
                            transform: rotate(180deg);
                        }
                        &::after{
                            bottom: -25px;
                        }
                        
                    }
                }
                .bot{
                    justify-content: space-between;

                    .item{
                        &:after{
                            top: -23px;
                        }
                    }
                }
                .line{
                    width: 100%;
                    img{
                        width: 100%;
                    }
                }
            }
        }
        @media (max-width: 992px){
            padding-top: 86px;
            .banner{
                height: calc(100vh - 86px);
                >img{
                    &.img_token_1{
                        top: 4%;
                    }
                    &.img_token_2{
                        top: 22%;

                    }
                    &.img_token_3{
                        bottom: 5%;

                    }
                    &.img_token_4{
                        bottom: 15%;

                    }
                }
                h2{
                    font-size: 42px;
                    text-align: center;
                    margin-bottom: 0px;
                }
                p{
                    font-size: 18px;
                    text-align: center;
                    padding: 0 20px;
                    line-height: 22px;
                }
            }
            .unility{
                padding: 30px 20px;
                .listBox{
                    width: 100%;
                    gap: 80px;
                    .item{
                        width: 100%;
                        gap: 20px;
                        align-items: flex-start;
                        .imgContent{
                            padding: 10px;
                        }
                        .content{
                            p{
                                br{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .tokenomic{
                padding: 60px 0 20px;
                .chartDonut{
                    flex-direction: column;
                    padding: 0 20px;
                    .addChart{
                        width: 100%;
                        span.totalSupply{
                            font-size: 40px;
                        }
                    }
                    .legendChart{
                        width: 100%;
                        padding: 0 20px;
                        margin-top: 40px;
                        ul{
                            width: 100%;
                        }
                    }
                }
                .lineChart{
                    padding: 20px;
                }
                .box{
                    padding: 0 20px;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    .listBox{
                        flex-direction: column;
                    }
                }
            }
            .roadmap{
                .content{
                    display: flex;
                    padding: 0 20px;
                    gap: 20px;
                    position: relative;
                    img.line_rn_vert{
                        display: block;
                        position: absolute;
                        left: 47.8%;
                        top: 0;
                        transform: translateX(-50%);

                    }
                    .top, .bot{
                        flex-direction: column;
                        padding: 0 0px;
                        width: 50%;
                        justify-content: unset;
                        .item{
                            width: auto;
                            height: 100px;
                            p{
                                white-space: pre-wrap;
                                padding: 0 20px;
                            }
                        }
                    }
                    .top{
                        padding-top: 85px;
                        .item{
                            margin-bottom: 70px;
                            &:after{
                                top: 0%;
                                right: -22px;
                                left: unset;
                                transform: translate(0%, 0%) rotate(45deg);
                            }
                            img{
                                position: absolute;
                                right: 20px;
                                transform: rotate(90deg);
                                top: -13px;
                                height: 50px;
                            }
                        }
                    }
                    .bot{
                        .item{
                            margin-bottom: 70px;
                            &:after{
                                top: 0%;
                                left: -22px;
                                transform: translate(0%, 0%) rotate(45deg);
                            }
                            img{
                                position: absolute;
                                left: 20px;
                                transform: rotate(-90deg);
                                top: -13px;
                                height: 50px;
                            }
                        }
                    }
                    .line{
                        display: none;
                    }
                }
            }
        }
    }
</style>