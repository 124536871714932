<template>
    <div>
        <header>
            <div class="header">
                <div class="logo">
                    <a href="/"><img src="../assets/logo_head.png" alt=""></a>
                    <a href="https://nft.zyncas.com" target="_blank">NFT Page</a>
                </div>
                <div class="iconMenu">
                    <div id="nav-icon" :class="flgMenu ? 'open' : ''" @click="openMenu">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <nav :class="flgMenu ? 'open' : ''">
                    <ul>
                        <li><a href="#utilities">Token Utilities</a></li>
                        <li><a href="#tokenomic">Tokenomics</a></li>
                        <li><a href="#roadmap">Roadmap</a></li>
                        <!-- <li><a href="" target="_blank">Whitepaper</a></li> -->
                        <li><a href="https://app.signals.zyncas.com/w5Eb" target="_blank">Buy NFT</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                flgMenu: false,
            }
        },
        methods:{
            openMenu(){
                this.flgMenu = !this.flgMenu;
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    header{
        position: fixed;
        width: 100%;
        top: 0;
        padding: 15px 0px 0;
        background: #fff;
        z-index: 9999;
        .header{
            display: flex;
            justify-content: space-between;
            padding: 20px 60px;
            align-items: center;
            border-top: 1px solid rgba(0, 0, 0, 0.5);
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
            .logo{
                display: flex;
                align-items: center;
                a{
                    text-decoration: none;
                    color: #000;
                    text-transform: uppercase;
                    font-weight: 500;
                    &:first-child{
                        img{
                            width: 50px;
                        }
                    }
                    &:last-child{
                        margin-left: 20px;
                        padding-left: 20px;
                        border-left: 1px solid #000;
                        img{
                            margin-right: 5px;
                            margin-top: -3px;
                        }
                    }
                }
            }
            .iconMenu{
                display: none;
                #nav-icon{
                    width: 50px;
                    height: 45px;
                    position: relative;
                    margin: 0px auto;
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    transform: rotate(0deg);
                    -webkit-transition: .5s ease-in-out;
                    -moz-transition: .5s ease-in-out;
                    -o-transition: .5s ease-in-out;
                    transition: .5s ease-in-out;
                    cursor: pointer;
                    span{
                        display: block;
                        position: absolute;
                        height: 4px;
                        width: 50%;
                        background: #000;
                        opacity: 1;
                        -webkit-transform: rotate(0deg);
                        -moz-transform: rotate(0deg);
                        -o-transform: rotate(0deg);
                        transform: rotate(0deg);
                        -webkit-transition: .25s ease-in-out;
                        -moz-transition: .25s ease-in-out;
                        -o-transition: .25s ease-in-out;
                        transition: .25s ease-in-out;
                        &:nth-child(even){
                            left: 50%;
                            border-radius: 0 9px 9px 0;
                        }
                        &:nth-child(odd){
                            left: 1px;
                            border-radius: 9px 0 0 9px;
                        }
                        &:nth-child(1), &:nth-child(2){
                            top: 0px;
                        }
                        &:nth-child(3), &:nth-child(4){
                            top: 14px;
                        }
                        &:nth-child(5), &:nth-child(6){
                            top: 28px;
                        }
                    }
                    &.open{
                        z-index: 9991;
                        span{
                            background: #000;
                            &:nth-child(1), &:nth-child(6){
                                transform: rotate(45deg);
                            }
                            &:nth-child(2), &:nth-child(5){
                                transform: rotate(-45deg);
                            }
                            &:nth-child(1){
                                top: 10px;
                                left: 5px;
                            }
                            &:nth-child(2){
                                left: calc(50% - 5px);
                                top: 10px;
                            }
                            &:nth-child(3){
                                left: -50%;
                                opacity: 0;
                            }
                            &:nth-child(4){
                                left: 100%;
                                opacity: 0;
                            }
                            &:nth-child(5){
                                left: 5px;
                                top: 25px;
                            }
                            &:nth-child(6){
                                left: calc(50% - 5px);
                                top: 25px;
                            }
                        }
                    }
                }
            }
            nav{
                ul{
                    display: flex;
                    gap: 20px;
                    li{
                        a{
                            text-decoration: none;
                            text-transform: uppercase;
                            font-size: 14px;
                            color: #000;
                            font-weight: 500;
                            padding: 8px 12px;
                            transition: all .5s ease;
                            border-top: 1px solid transparent;
                            border-bottom: 1px solid transparent;
                            cursor: pointer;
                            @media (min-width: 993px){
                                &:hover{
                                    border-top: 1px solid #000;
                                    border-bottom: 1px solid #000;
                                }
                            }
                        }
                        &:last-child{
                            a{
                                background: #000;
                                color: #fff;
                                border-radius: 5px;
                                border: 1px solid #000;
                                @media (min-width: 993px){
                                    &:hover{
                                        background: #fff;
                                        color: #000;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 992px){
            padding-top: 0;
            .header{
                padding: 20px;
                border-top: 0;
                .iconMenu{
                    display: block;
                }
                nav{
                    position: fixed;
                    width: 100%;
                    right: -100%;
                    height: 100vh;
                    top: 0;
                    z-index: 999;
                    background: rgba(255, 255, 255, 0.4);
                    backdrop-filter: blur(6px);
                    transition: all .5s ease;
                    opacity: 0;
                    visibility: hidden;
                    ul{
                        flex-direction: column;
                        width: 70%;
                        background: #fff;
                        height: 100%;
                        margin-left: auto;
                        justify-content: center;
                        align-items: end;
                        padding-right: 40px;
                        li{
                            a{
                                color: #000;
                                font-size: 18px;
                                line-height: 24px;
                                margin-bottom: 20px;
                                display: block;
                            }
                        }
                    }
                    &.open{
                        right: 0%;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
</style>
